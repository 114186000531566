<template>
  <v-container justify-center id="login" class="fill-height" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">{{ $t("recovery-pwd-title") }}</div>
          </template>
          <v-card-text class="text-center">
            <ValidationObserver v-slot="{ invalid, validated }">
              <div class="container-logo-small-pages"></div>
              <v-form v-if="!emailSuccess" @submit="recovery()">
                <ValidationProvider
                  rules="required|email"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    color="secondary"
                    :error-messages="errors"
                    :success="valid"
                    v-model="email"
                    :label="$t('recovery-pwd-email')"
                    prepend-icon="mdi-email"
                    required
                  />
                </ValidationProvider>

                <v-btn
                  type="submit"
                  :disabled="invalid || !validated"
                  @click="recovery()"
                  v-if="!verifyingEmail"
                  color="primary"
                  >{{ $t("recovery-pwd-recovery") }}</v-btn
                >
              </v-form>
              <v-progress-circular
                v-if="verifyingEmail"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="light-blue"
              ></v-progress-circular>

              <div v-if="emailError">
                <div style="height:10px"></div>
                <v-alert type="error" dense dark dismissible>
                  <span class="text-uppercase" v-text="emailError" />
                </v-alert>
              </div>

              <div v-if="emailSuccess">
                <div style="height:10px"></div>
                <v-alert type="success" dense>
                  <span class="text-uppercase">
                    {{ $t("recovery-pwd-message") }}
                  </span>
                </v-alert>
                <v-btn
                  class="white--text"
                  type="submit"
                  to="login"
                  :disabled="invalid || !validated"
                  v-if="!verifyingEmail"
                  color="primary"
                  >{{ $t("recovery-pwd-back") }}</v-btn
                >
              </div>
            </ValidationObserver>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import userManager from "../apis/users";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      email: "",
      emailError: null,
      verifyingEmail: false,
      email_state: true,
      emailSuccess: false,
      dismissCountDown: 2
    };
  },

  mounted() {
    this.email = "";
    (this.emailError = null), (this.verifyingEmail = false);
    this.email_state = true;
    this.emailSuccess = false;
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  methods: {
    validateForm: function() {
      this.email_state = this.$refs.email.checkValidity();
      if (this.email_state) {
        this.recovery();
      }
    },

    recovery: function() {
      this.verifyingEmail = true;
      userManager
        .recoveryAccount(this.email)
        .then(result => {
          if (result.data.responseData.responseError) {
            this.verifyingEmail = false;
            this.emailError = result.data.responseData.responseError;
          } else {
            this.verifyingEmail = false;
            this.emailSuccess = true;
            this.emailError = null;
          }
        })
        .catch(err => {
          this.verifyingEmail = false;
          this.emailError = err;
        });
    }
  }
};
</script>
